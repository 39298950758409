<template>
  <div>
    <div
      @dragenter.prevent="toggleActive"
      @dragleave.prevent="toggleActive"
      @dragover.prevent
      @change="selectedFile"
      @drop.prevent="drop"
      :class="{ 'active-dropzone': active }"
      class="dropzone p-2 mb-4"
    >
      <label :for="'dropzoneFile' + unique">Chọn file</label>
      <span v-if="uploadStatus === 'uploading' || uploadStatus === 'converting'"
        >{{ uploadText }}
        <span v-if="uploadStatus === 'uploading'"
          >{{ uploadPercent }}%</span
        ></span
      >
      <span v-if="message" class="text-xs">{{ message }}</span>
      <input type="file" :id="'dropzoneFile' + unique" class="dropzoneFile" />
    </div>

    <player
      :data-setup="dataSetup"
      v-model:type="musicType"
      :key="key"
      v-model:options="playerOptions"
      v-if="type === 'music' && objectId"
    />

    <div v-if="type === 'image' && image">
      <img :src="image" :class="'object-cover ' + imageClass" v-if="objectId" />
      <div class="flex justify-content-end" v-if="objectId">
        <button v-on:click="removeObject" class="text-xs mt-2 text-red-600">
          Xóa ảnh
        </button>
      </div>
    </div>

    <div v-if="type === 'pdf' && pdf" class="w-full">
      <div
        :class="'grid divide divide-gray-200 gap-x-4 mt-4 md:grid-cols-' + cols"
        style="overflow-y: scroll; height: calc(100vh - 200px)"
      >
        <div
          class="mb-4"
          v-for="page in pageCount"
          :key="'page' + page + pdfKey"
        >
          <a
            class="uk-drop-close"
            href="#"
            v-on:click="selectPage(page)"
            uk-toggle="target: #pdfModal"
          >
            <vue-pdf-embed :source="pdf" :page="page" ref="pdfRef123"/>
          </a>

          <div class="text-center">{{ page }}</div>
        </div>
      </div>

      <div class="flex justify-content-end" v-if="objectId">
        <button v-on:click="removeObject" class="text-xs mt-2 text-red-600">
          Xóa pdf
        </button>
      </div>

      <div id="pdfModal" class="create-post" uk-modal>
        <div style="width: 1000px;"
          class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small"
        >
          <div class="text-center py-3 border-b">
            <h3 class="text-lg font-semibold">{{ currentPage }}</h3>
            <button
              class="updateSheetTagClose uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
              type="button"
              uk-close
              uk-tooltip="title: Close ; pos: bottom ;offset:7"
            ></button>
          </div>

          <div>
            <vue-pdf-embed
              ref="pdfRef"
              :key="'pdf' + pdfKey"
              @rendered="handleDocumentRender"
              :source="pdf"
              :page="currentPage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../core/services/api.service";
import Player from "./Player";
import VuePdfEmbed from "vue-pdf-embed";

export default {
  name: "DropZone",
  components: {
    Player,
    VuePdfEmbed,
  },
  props: {
    type: String,
    objectIdProp: String,
    playerOptionsProp: Object,
    musicTypeProp: String,
    imageProp: String,
    pdfProp: String,
    height: String,
    cols: String,
    imageClass: String,
  },
  created() {
    if (this.objectIdProp) {
      this.objectId = this.objectIdProp;
    }

    if (this.playerOptionsProp) {
      this.playerOptions = this.playerOptionsProp;
    }

    if (this.musicTypeProp) {
      this.musicType = this.musicTypeProp;
    }

    if (this.imageProp) {
      this.image = this.imageProp;
    }

    if (this.pdfProp) {
      this.pdf = this.pdfProp;
    }
  },
  data() {
    return {
      unique: Math.round(Math.random() * 1000000),
      key: "",
      dataSetup: '{"poster": "https://static.bcdcnt.net/assets/images/bcdcnt-bg.png", "fluid": true}',
      playerOptions: {
        autoplay: false,
        loop: false,
        controls: true,
        bigPlayButton: true,
        sources: [],
      },
      active: false,
      dropzoneFile: null,
      image: "",
      pdf: "",
      pdfKey: this.generatePdfKey(),
      uploadPercent: 0,
      uploadStatus: "",
      uploadText: "",
      musicType: "audio",
      objectId: null,
      message: "",
      pageCount: 1,
      currentPage: 1,
    };
  },
  methods: {
    selectedFile() {
      this.message = "";
      this.dropzoneFile = document.querySelector(
        "#dropzoneFile" + this.unique
      ).files[0];
    },
    toggleActive() {
      this.active = !this.active;
    },
    handleUpload() {
      this.message = "";
      if (this.dropzoneFile) {
        if (this.type === "music") {
          this.uploadStatus = "uploading";
          this.uploadText = "Đang upload...";
          ApiService.uploadMusic(this.dropzoneFile, null, {
            onUploadProgress: this.onUploadProgress,
          })
            .then(({ data }) => {
              if (data.success) {
                this.musicType = data.data.type;
                let src =
                  data.data.type === "audio"
                    ? data.data.audio_url
                    : data.data.video_url;
                let type =
                  data.data.type === "audio" ? "audio/mp3" : "video/mp4";
                this.playerOptions.sources[0] = {
                  src: src,
                  type: type,
                };
                this.uploadStatus = "finished";
                this.key = Math.round(Math.random() * 1000);
                this.objectId = data.data.id;
                this.$emit("uploadDone", data.data);
              } else {
                this.message = data.message;
                this.uploadStatus = "finished";
              }
            })
            .catch((response) => {
              console.log(response);
              this.message = response.message;
              this.uploadStatus = "finished";
            });
        } else if (this.type === "image") {
          ApiService.uploadImage(this.dropzoneFile, null, {
            onUploadProgress: this.onUploadProgress,
          })
            .then(({ data }) => {
              if (data.success) {
                this.image = data.data.url;
                this.uploadStatus = "finished";
                this.objectId = data.data.id;
                this.$emit("uploadDone");
              } else {
                this.message = data.message;
                this.uploadStatus = "finished";
              }
            })
            .catch((response) => {
              console.log(response);
              this.message = response.message;
              this.uploadStatus = "finished";
            });
        } else if (this.type === "pdf") {
          this.uploadStatus = "uploading";
          this.uploadText = "Đang upload...";
          ApiService.uploadTmp(
            this.dropzoneFile,
            this.unique + ".pdf",
            { onUploadProgress: this.onUploadProgress }
          ) 
            .then(({ data }) => {
              if (data.success) {
                this.pdf = data.data.url;
                this.pdfKey = this.generatePdfKey();
                this.objectId = data.data.url;
                this.uploadStatus = "finished";
                this.$emit("uploadDone");
                console.log("this.pdf", this.pdf, this.pdfKey);
              } else {
                console.log(data.message);
                this.message = data.message;
                this.uploadStatus = "finished";
              }
            })
            .catch((response) => {
              console.log(response);
              this.message = response.message;
              this.uploadStatus = "finished";
            });
        }
      }
    },
    generatePdfKey() {
      return "pdfKey" + Math.round(Math.random() * 1000000);
    },
    drop(e) {
      this.toggleActive();
      this.dropzoneFile = e.dataTransfer.files[0];
    },
    onUploadProgress(progressEvent) {
      this.uploadPercent = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      this.$emit("onUploadProgress", this.uploadPercent);
      if (this.uploadPercent === 100 && this.type === "music") {
        this.uploadText = "Đang convert...";
        this.uploadStatus = "converting";
      }
    },
    removeObject() {
      this.objectId = null;
      this.dropzoneFile = null;
      if (this.type === "pdf") {
        this.pdfKey = this.generatePdfKey();
        this.pdf = "";
      }
    },
    handleDocumentRender() {
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
    selectPage(page) {
      this.currentPage = page;
    },
  },
  watch: {
    dropzoneFile: {
      handler() {
        this.handleUpload();
      },
    },
    objectId: {
      handler(newVal) {
        this.$emit("update:objectIdProp", newVal);
      },
    },
    objectIdProp: {
      handler(newVal) {
        this.objectId = newVal;
      },
    },
    playerOptionsProp: {
      handler(newVal) {
        this.playerOptions = newVal;
      },
      deep: true,
    },
    musicTypeProp: {
      handler(newVal) {
        this.musicType = newVal;
      },
    },
    imageProp: {
      handler(newVal) {
        this.image = newVal;
      },
    },
    cols: {
      handler() {
        this.pdfKey = this.generatePdfKey();
      },
    },
  },
};
</script>

<style scoped>
.dropzone {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  border: 2px dashed #41b883;
  background-color: #fff;
  transition: 0.3s ease all;
}

.dropzone label {
  padding: 8px 12px;
  color: #fff;
  background-color: #41b883;
  transition: 0.3s ease all;
  border-radius: 10px;
}

.dropzone label:hover {
  cursor: pointer;
}

.dropzone input {
  display: none;
}

.active-dropzone {
  color: #fff;
  border-color: #fff;
  background-color: #41b883;
}

.active-dropzone label {
  background-color: #fff;
  color: #41b883;
}
</style>
